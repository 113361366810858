import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DefaultLayout from "../../layouts/DefaultLayout";
import { base64 } from "../../constants/schrodinger";
import { fetchEigenstatesRequest } from '../../redux/actions/eigenstates';
import { Typography, Slider, Box, Backdrop, CircularProgress } from '@mui/material'

const Schrodinger2D = () => {
  const [selectedOption, setSelectedOption] = useState('four_gaussian_wells');
  const [val, setVal] = useState(0);
  const [maxVal, setMaxVal] = useState(59);

  const handleChange = (_, newValue) => {
    setVal(newValue);
  };
  const dispatch = useDispatch();
  const engenState = useSelector((state) => state.eigenstatesReducer);

  const options = [
    { value: 'four_gaussian_wells', label: '2D Four Gaussian Wells' },
    { value: 'harmonic_oscillator', label: '2D Harmonic Oscillator' },
    { value: 'harmonic_oscillator_magneticfield', label: '2D Harmonic Oscillator MagneticField' },
    { value: 'particle_in_a_box_magneticfield', label: '2D Particle in a Box MagneticField' },
    { value: 'quantum_tunneling', label: '2D Quantum Tunneling' }
  ];

  const handleSelectChange = (event) => {
    setVal(0);
    setSelectedOption(event.target.value);
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const debouncedFetchEigenstates = useCallback(
    debounce((visualizer_chart, val) => {
      dispatch(fetchEigenstatesRequest({visualizer_chart: visualizer_chart, state: val}));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    let visualizer_chart;
    let maxVal;
    switch (selectedOption) {
      case 'four_gaussian_wells':
        visualizer_chart = 'four_gaussian_wells';
        maxVal = 59;
        break;
      case 'harmonic_oscillator':
        visualizer_chart = 'harmonic_oscillator';
        maxVal = 29;
        break;
      case 'harmonic_oscillator_magneticfield':
        visualizer_chart = 'harmonic_and_magnetic_interaction';
        maxVal = 27;
        break;
      case 'particle_in_a_box_magneticfield':
        visualizer_chart = 'magnetic_interaction';
        maxVal = 27;
        break;
      case 'quantum_tunneling':
        visualizer_chart = 'two_gaussian_wells';
        maxVal = 39;
        break;
      default:
        visualizer_chart = 'four_gaussian_wells';
        maxVal = 59;
    }
    setMaxVal(maxVal);
    debouncedFetchEigenstates(visualizer_chart, val)
  }, [val, selectedOption, debouncedFetchEigenstates]);

  return (
    <DefaultLayout title="Schrödinger Simulator">
      <div>
        <select id="schrodinger-select" value={selectedOption} onChange={handleSelectChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="wrap-mpld3-chart">
          {engenState.data === null ? (
            <img className="img-mpld3" src={`data:image/png;base64,${base64}`} alt="Base64 Image" />
          ) : (
            <img className="img-mpld3" src={`data:image/png;base64,${engenState.data}`} alt="Base64 Image" />
          )}
          <Box sx={{ maxWidth: '746px', width: '100%', margin: '10px auto' }}>
            <Slider
              step={1}
              value={val}
              valueLabelDisplay="auto"
              min={0}
              max={maxVal}
              onChange={handleChange}
              className="slider-mpld3"
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="body2"
                onClick={() => setVal(0)}
                sx={{ cursor: 'pointer', color: '#fff' }}
              >
                0 min
              </Typography>
              <Typography
                variant="body2"
                onClick={() => setVal(maxVal)}
                sx={{ cursor: 'pointer', color: '#fff' }}
              >
                {maxVal} max
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
      {engenState?.loading &&
        <Backdrop
          open
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: (theme) => theme.palette.common.white,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    </DefaultLayout>
  );
};

export default Schrodinger2D;