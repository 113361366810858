import {all,call,fork,put,takeEvery} from "redux-saga/effects";
import { ActionTypes } from '../redux/contants/action-types';
import { fetchEigenstates } from '../api/eigenstates'

import { 
  fetchEigenstatesSuccess,
  fetchEigenstatesFailure
 } from '../redux/actions/eigenstates'

function* watchEigenstates({payload}){
  const res = yield call(fetchEigenstates, payload)
  if(res?.status === 200){
    yield put(fetchEigenstatesSuccess(res?.data))
  } else {
    yield put(fetchEigenstatesFailure(res?.data))
  }
}

function* handleListEigenstates() {
  yield takeEvery(ActionTypes.FETCH_EIGENSTATES_REQUEST, watchEigenstates);
}

export default function* eigenstates() {
  yield all([
    fork(handleListEigenstates), 
  ]);
}