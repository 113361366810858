import Dashboard from "./pages/Dashboard/Index";
import Login from "./pages/Login/Index";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ListUser from "./pages/Users/List/Index";
import CreateUser from "./pages/Users/Create/Index";
import PlatformList from "./pages/Platform/List/Index";
import PlatfromCreate from "./pages/Platform/Create/Index";
import PlatformDetail from "./pages/Platform/Detail/Index";
import NotFound from "./pages/NotFound/Index";
import Settings from "./pages/Settings/Index";
import Instruments from "./pages/Instruments/List/Index";
import InstrumentCreate from "./pages/Instruments/Create/Index";
import InstrumentDetail from "./pages/Instruments/Detail/Index";
import useToken from "./helpers/useToken";
import UpdateVmConfiguration from "./pages/Platform/UpdateVmConfiguration/Index";

import InstrumentController from "./pages/InstrumentController/List/Index";
import InstrumentControllerDetail from "./pages/InstrumentController/Detail/Index";
import InstrumentControllerCreate from "./pages/InstrumentController/Create/Index";

import MeasurementController from "./pages/MeasurementController/List/Index";
import MeasurementControllerDetail from "./pages/MeasurementController/Detail/Index";
import MeasurementControllerCreate from "./pages/MeasurementController/Create/Index";
// 서킷
import Circuit from "./pages/Circuit/Index";

import InstrumentDriver from "./pages/InstrumentsDriver/Create/Index";
import InstrumentsDriverList from "./pages/InstrumentsDriver/List";
import InstrumentDriverDetail from "./pages/InstrumentsDriver/List/Detail";

import MeasurementDriver from "./pages/MeasurementDriver/Create/Index";
import MeasurementDriverList from "./pages/MeasurementDriver/List";
import MeasurementDriverDetail from "./pages/MeasurementDriver/List/Detail";
import Backend from "./pages/Backend";

import Transpiler from "./pages/Transpiler";
import Transpiler2 from "./pages/Transpiler2";
import Simulator from "./pages/Simulator/List/Index";
import SimulatorCreate from "./pages/Simulator/Create/Index";
import SimulatorDetail from "./pages/Simulator/Detail/Index";
import Schrodinger1DWavefunction from "./pages/SchrodingerSimulator/1DWavefunction.jsx";
import Schrodinger1D from "./pages/SchrodingerSimulator/1D.jsx";
import Schrodinger2D from "./pages/SchrodingerSimulator/2D.jsx";

// QCFD
import QCFD from "./pages/Simulator/QCFD/Index";

// 벤치마크
import Benchmark from "./pages/Benchmark/Index";

// Release Note
import Release from "./pages/Release/Index";

// Panqec
import Panqec from "./pages/Panqec/Index";

function App() {
  const { token } = useToken();

  return (
    <Router>
      {!token && <Redirect to={"/login"} />}
      <Switch>
        <Route exact path={"/"} component={Dashboard} />
        <Route exact path={"/user/list"} component={ListUser} />
        <Route exact path={"/user/create"} component={CreateUser} />
        <Route exact path={"/platform/list"} component={PlatformList} />
        <Route exact path={"/platform/create"} component={PlatfromCreate} />
        <Route exact path={"/platform/update/:id"} component={PlatfromCreate} />
        <Route exact path={"/platform/:id"} component={PlatformDetail} />
        <Route
          exact
          path={"/platform/:id/update-vm-configuration"}
          component={UpdateVmConfiguration}
        />
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/circuit"} component={Circuit} />
        <Route exact path={"/setting"} component={Settings} />
        <Route exact path={"/instrument/list"} component={Instruments} />
        <Route exact path={"/instrument/create"} component={InstrumentCreate} />
        <Route exact path={"/instrument/:id"} component={InstrumentDetail} />
        <Route
          exact
          path={"/instrument-controller/list"}
          component={InstrumentController}
        />
        <Route
          exact
          path={"/instrument-controller/create"}
          component={InstrumentControllerCreate}
        />
        <Route
          exact
          path={"/instrument-controller/update/:id"}
          component={InstrumentControllerCreate}
        />
        <Route
          exact
          path={"/instrument-controller/:id"}
          component={InstrumentControllerDetail}
        />

        <Route
          exact
          path={"/measurement-controller/list"}
          component={MeasurementController}
        />
        <Route
          exact
          path={"/measurement-controller/create"}
          component={MeasurementControllerCreate}
        />
        <Route
          exact
          path={"/measurement-controller/update/:id"}
          component={MeasurementControllerCreate}
        />
        <Route
          exact
          path={"/measurement-controller/:id"}
          component={MeasurementControllerDetail}
        />

        <Route
          exact
          path={"/instrument-driver/create"}
          component={InstrumentDriver}
        />
        <Route
          exact
          path={"/instrument-driver/list"}
          component={InstrumentsDriverList}
        />
        <Route
          exact
          path={"/instrument-driver/:id"}
          component={InstrumentDriverDetail}
        />

        <Route
          exact
          path={"/measurement-driver/create"}
          component={MeasurementDriver}
        />
        <Route
          exact
          path={"/measurement-driver/list"}
          component={MeasurementDriverList}
        />
        <Route
          exact
          path={"/measurement-driver/:id"}
          component={MeasurementDriverDetail}
        />

        {/* Backend */}
        <Route exact path={"/backend/:id"} component={Backend} />

        <Route exact path={"/transpiler"} component={Transpiler} />
        <Route exact path={"/transpiler2"} component={Transpiler2} />

        {/* Simulator */}
        <Route
          exact
          path={"/simulation/simulator/create"}
          component={SimulatorCreate}
        />
        <Route exact path={"/simulation/simulator"} component={Simulator} />
        <Route
          exact
          path={"/simulation/simulator/:id"}
          component={SimulatorDetail}
        />
        {/* QCFD */}
        <Route exact path={"/simulation/qcfd"} component={QCFD} />

        {/* Benchmark */}
        <Route exact path={"/benchmark"} component={Benchmark} />

        {/* Release Note */}
        <Route exact path={"/release"} component={Release} />

        <Route exact path={"/simulation/panqec"} component={Panqec} />
        <Route exact path={"/schrodinger-simulator/1d-wavefunction"} component={Schrodinger1DWavefunction} />
        <Route exact path={"/schrodinger-simulator/1d"} component={Schrodinger1D} />
        <Route exact path={"/schrodinger-simulator/2d"} component={Schrodinger2D} />

        <Route exact path="*" component={NotFound} />
        
      </Switch>
    </Router>
  );
}

export default App;
