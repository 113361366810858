import { useEffect, useRef, useState } from "react";
import "./css/column.css";
import "./css/normalize.css";
import "./css/webflow.css";
import {sketch} from "./js";
import { ReactP5Wrapper } from "react-p5-wrapper";
import DefaultLayout from "../../layouts/DefaultLayout";

const Schrodinger1DWavefunction = () => {
  const p5Ref = useRef(null);
  const [p5Instance, setP5Instance] = useState(null);
  const [isSetup, setIsSetup] = useState(false);

  useEffect(() => {
    // Wait for the DOM to load
    // const p5 = window.p5;
    if (!p5Instance || !p5Ref.current) return;
    !isSetup && sketch?.setup(p5Instance, p5Ref.current);
    setIsSetup(true);
  }, [p5Instance, p5Ref.current]);
  return (
    <DefaultLayout title="Schrödinger Simulator">
      <div className="columns w-row">
        <div className="w-col w-col-12">
          <div className="infowrapper">
            <div id="canvas-wrapper">
              <div id="container" ref={p5Ref}>
              <ReactP5Wrapper sketch={(p) => {
                  if(!p5Instance) {
                    setP5Instance(p);
                    window.p5 = p;
                  }
              }} ></ReactP5Wrapper>
              </div>
            </div>

            <div className="control-button-wrapper">
              <div id="container_reset" className="button-wrapper"></div>
              <div
                id="container_run_pause"
                className="button-wrapper"
              ></div>
              <div id="container_download" className="button-wrapper"></div>
            </div>

            <div className="w-row">
              <div className="w-col w-col-2">
                <div className="potential-wrapper">
                  <h5 className="text-white">Potential Options</h5>
                  <div id="container_potentials"></div>
                </div>
              </div>
              <div className="w-col w-col-3">
                <div className="potential-wrapper">
                  <h5 className="text-white">Potential parameters</h5>
                  <div id="container_potential_parameters_height">
                    <label className="text-white">Potential height:</label>
                  </div>
                  <div id="value_height"></div>
                  <div id="container_potential_parameters_customized">
                    <label></label>
                  </div>
                </div>
              </div>
              <div className="w-col w-col-2">
                <div className="potential-wrapper">
                  <h5 className="text-white">Initial state options</h5>
                  <div id="container_psi"></div>
                </div>
              </div>
              <div className="w-col w-col-5">
                <div className="potential-wrapper">
                  <h5 className="text-white">Initial state parameters</h5>
                  <div id="container_psi_parameters_n">
                    <label className="text-white">Level n:</label>
                  </div>

                  <div id="container_psi_parameters_position">
                    <label className="text-white">Position:</label>
                    <div id="value_position" className="value-style"></div>
                  </div>

                  <div id="container_psi_parameters_velocity">
                    <label className="text-white">Velocity:</label>
                    <div id="value_velocity" className="value-style"></div>
                  </div>

                  <div id="container_psi_parameters_variance">
                    <label className="text-white">Variance:</label>
                    <div id="value_variance" className="value-style"></div>
                  </div>
                  <div id="container_psi_parameters_customized">
                    <label className="text-white">Custom wavefunction:</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default Schrodinger1DWavefunction;