import { combineReducers } from "redux";
import { authReducer } from './auth';
import { platformReducer } from './platform';
import {instrumentReducer} from './instrument'
import { eigenstatesReducer } from './eigenstates';


const reducers = combineReducers({
     authReducer : authReducer,
     platformReducer: platformReducer,
     instrumentReducer: instrumentReducer,
     eigenstatesReducer: eigenstatesReducer
});

export default reducers;