export const ActionTypes = {
    REGISTER_USER: 'REGISTER_USER',
    REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',

    LOGIN_USER: 'LOGIN_USER',
    LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',

    LIST_USER: 'LIST_USER',
    GET_LIST_SUCCESS: 'GET_LIST_SUCCESS', 
    GET_LIST_ERROR: 'GET_LIST_ERROR',

    CREATE_PLATFORM: 'CREATE_PLATFORM',
    CREATE_PLATFORM_SUCCESS: 'CREATE_PLATFORM_SUCCESS',
    CREATE_PLATFORM_ERROR: 'CREATE_PLATFORM_ERROR',
    LIST_PLATFORM: 'LIST_PLATFORM',
    LIST_PLATFORM_SUCCESS: 'LIST_PLATFORM_SUCCESS',
    LIST_PLATFORM_ERROR: 'LIST_PLATFORM_ERROR',

    DELETE_PLATFORM: 'DELETE_PLATFORM',
    DELETE_PLATFORM_SUCCESS: 'DELETE_PLATFORM_SUCCESS',
    DELETE_PLATFORM_ERROR: 'DELETE_PLATFORM_ERROR',

    DETAIL_PLATFORM: 'DETAIL_PLATFORM',
    DETAIL_PLATFORM_SUCCESS: 'DETAIL_PLATFORM_SUCCESS',
    DETAIL_PLATFORM_ERROR: 'DETAIL_PLATFORM_ERROR',

    UPLOAD_FILE_RUNCARD : 'UPLOAD_FILE_RUNCARD',
    UPLOAD_FILE_RUNCARD_SUCCESS: 'UPLOAD_FILE_RUNCARD_SUCCESS',
    UPLOAD_FILE_RUNCARD_ERROR: 'UPLOAD_FILE_RUNCARD_ERROR',

    LIST_FILE_RUNCARD: 'LIST_FILE_RUNCARD',
    LIST_FILE_RUNCARD_SUCCESS: 'LIST_FILE_RUNCARD_SUCCESS',
    LIST_FILE_RUNCARD_ERROR: 'LIST_FILE_RUNCARD_ERROR',

    LIST_INSTRUMENT: 'LIST_INSTRUMENT',
    LIST_INSTRUMENT_SUCCESS: 'LIST_INSTRUMENT_SUCCESS',
    LIST_INSTRUMENT_ERROR: 'LIST_INSTRUMENT_ERROR',

    LIST_INSTRUMENT_DRIVER: 'LIST_INSTRUMENT_DRIVER',
    LIST_INSTRUMENT_DRIVER_SUCCESS: 'LIST_INSTRUMENT_DRIVER_SUCCESS',
    LIST_INSTRUMENT_DRIVER_ERROR: 'LIST_INSTRUMENT_DRIVER_ERROR',

    CREATE_INSTRUMENT: 'CREATE_INSTRUMENT',
    CREATE_INSTRUMENT_SUCCESS: 'CREATE_INSTRUMENT_SUCCESS',
    CREATE_INSTRUMENT_ERROR: 'CREATE_INSTRUMENT_ERROR',

    DETAIL_INSTRUMENT: 'DETAIL_INSTRUMENT',
    DETAIL_INSTRUMENT_SUCCESS: 'DETAIL_INSTRUMENT_SUCCESS',
    DETAIL_INSTRUMENT_ERROR: 'DETAIL_INSTRUMENT_ERROR',

    DELETE_INSTRUMENT: 'DELETE_INSTRUMENT',
    DELETE_INSTRUMENT_SUCCESS: 'DELETE_INSTRUMENT_SUCCESS',
    DELETE_INSTRUMENT_ERROR: 'DELETE_INSTRUMENT_ERROR',

    ACTIVE_FILE_RUNCARD: 'ACTIVE_FILE_RUNCARD',
    ACTIVE_FILE_RUNCARD_SUCCESS: 'ACTIVE_FILE_RUNCARD_SUCCESS',
    ACTIVE_FILE_RUNCARD_ERROR: 'ACTIVE_FILE_RUNCARD_ERROR',
    
    FETCH_EIGENSTATES_REQUEST: 'FETCH_EIGENSTATES_REQUEST',
    FETCH_EIGENSTATES_SUCCESS: 'FETCH_EIGENSTATES_SUCCESS',
    FETCH_EIGENSTATES_FAILURE: 'FETCH_EIGENSTATES_FAILURE',
}