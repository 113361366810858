import { ActionTypes } from '../contants/action-types';

export const fetchEigenstatesRequest = (payload) => {
  return {
    type : ActionTypes.FETCH_EIGENSTATES_REQUEST,
    payload
  }
}

export const fetchEigenstatesSuccess = (payload) => {
  return {
    type : ActionTypes.FETCH_EIGENSTATES_SUCCESS,
    payload
  }
}

export const fetchEigenstatesFailure = (payload) => {
  return {
    type : ActionTypes.FETCH_EIGENSTATES_FAILURE,
    payload
  }
}
