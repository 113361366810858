import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DefaultLayout from "../../layouts/DefaultLayout";
import { base64_1D } from "../../constants/schrodinger";
import { fetchEigenstatesRequest } from '../../redux/actions/eigenstates';
import { Typography, Slider, Box, Backdrop, CircularProgress } from '@mui/material'

const Schrodinger1D = () => {
  const [selectedOption, setSelectedOption] = useState('1D_harmonic_oscillator');
  const [val, setVal] = useState(0);
  const [maxVal, setMaxVal] = useState(29);

  const handleChange = (_, newValue) => {
    setVal(newValue);
  };
  const dispatch = useDispatch();
  const engenState = useSelector((state) => state.eigenstatesReducer);

  const options = [
    { value: '1D_harmonic_oscillator', label: '1D Harmonic Oscillator' },
    { value: '1D_harmonic_oscillator_superpositions', label: '1D Harmonic Oscillator Superpositions' },
    { value: '1D_interactive_fermions', label: '1D Interactive Fermions' },
    { value: '1D_interactive_fermions_HO', label: '1D Interactive Fermions HO' },
    { value: '1D_interactive_fermions_HO_superpositions', label: '1D Interactive Fermions HO Superpositions' },
    { value: '1D_non_interactive_bosons', label: '1D Non-Interactive Bosons' },
    { value: '1D_non_interactive_fermions', label: '1D Non-Interactive Fermions' },
    { value: '1D_non_interactive_fermions_HO', label: '1D Non-Interactive Fermions HO' }
  ];

  const handleSelectChange = (event) => {
    setVal(0);
    setSelectedOption(event.target.value);
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const debouncedFetchEigenstates = useCallback(
    debounce((visualizer_chart, val) => {
      dispatch(fetchEigenstatesRequest({visualizer_chart: visualizer_chart, state: val}));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    let visualizer_chart;
    let maxVal;
    switch (selectedOption) {
      case '1D_harmonic_oscillator':
        visualizer_chart = '1D_harmonic_oscillator';
        maxVal = 29;
        break;
      case '1D_harmonic_oscillator_superpositions':
        visualizer_chart = '1D_harmonic_oscillator_superpositions';
        maxVal = 29;
        break;
      case '1D_interactive_fermions':
        visualizer_chart = '1D_interactive_fermions';
        maxVal = 44;
        break;
      case '1D_interactive_fermions_HO':
        visualizer_chart = '1D_interactive_fermions_HO';
        maxVal = 15;
        break;
      case '1D_interactive_fermions_HO_superpositions':
        visualizer_chart = '1D_interactive_fermions_HO_superpositions';
        maxVal = 99;
        break;
      case '1D_non_interactive_bosons':
        visualizer_chart = '1D_non_interactive_bosons';
        maxVal = 48;
        break;
      case '1D_non_interactive_fermions':
        visualizer_chart = '1D_non_interactive_fermions';
        maxVal = 40;
        break;
      case '1D_non_interactive_fermions_HO':
        visualizer_chart = '1D_non_interactive_fermions_HO';
        maxVal = 36;
        break;
      default:
        visualizer_chart = '1D_harmonic_oscillator';
        maxVal = 29;
    }
    setMaxVal(maxVal);
    debouncedFetchEigenstates(visualizer_chart, val)
  }, [val, selectedOption, debouncedFetchEigenstates]);

  return (
    <DefaultLayout title="Schrödinger Simulator">
      <div>
        <select id="schrodinger-select" value={selectedOption} onChange={handleSelectChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="wrap-mpld3-chart">
          {engenState.data === null ? (
            <img className="img-mpld3" src={`data:image/png;base64,${base64_1D}`} alt="Base64 Image" />
          ) : (
            <img className="img-mpld3" src={`data:image/png;base64,${engenState.data}`} alt="Base64 Image" />
          )}
          <Box sx={{ maxWidth: '746px', width: '100%', margin: '10px auto' }}>
            <Slider
              step={1}
              value={val}
              valueLabelDisplay="auto"
              min={0}
              max={maxVal}
              onChange={handleChange}
              className="slider-mpld3"
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="body2"
                onClick={() => setVal(0)}
                sx={{ cursor: 'pointer', color: '#fff' }}
              >
                0 min
              </Typography>
              <Typography
                variant="body2"
                onClick={() => setVal(maxVal)}
                sx={{ cursor: 'pointer', color: '#fff' }}
              >
                {maxVal} max
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
      {engenState?.loading &&
        <Backdrop
          open
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: (theme) => theme.palette.common.white,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    </DefaultLayout>
  );
};

export default Schrodinger1D;